import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { cadastro } from '../../services/mysql'
import InputMask from 'react-input-mask';
import { cnpj, cpf } from 'cpf-cnpj-validator';

export default function Cadastro() {
    const [valorCpf, setValorCpf] = useState('')
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [confirmaSenha, setConfirmaSenha] = useState('')

    function realizaLogin() {
        if (!nome) {
            mensagemAlerta('Digite seu Nome')
            return
        }
        if (!cpf.isValid(valorCpf.replace(/[^a-z0-9]/gi, ''))) {
            mensagemAlerta('CPF inválido')
            return
        }
        if (!email) {
            mensagemAlerta('Digite seu Email')
            return
        }
        if (!senha) {
            mensagemAlerta('Digite sua Senha')
            return
        }
        if (!confirmaSenha) {
            mensagemAlerta('Confirme sua senha')
            return
        }
        if (senha !== confirmaSenha) {
            mensagemAlerta('As senhas não iguais')
            return
        }
        mensagemLoading('Buscando cadastrado..')
        const data = {
            nome: nome,
            cpf: valorCpf.replace(/[^a-z0-9]/gi, ''),
            email: email.toLocaleLowerCase(),
            senha: senha,
            perfil: 'ATLETA'
        }
        cadastro(data).then((res) => {
            if (res.erro) {
                Swal.close()
                mensagemAlerta(res.message)
            } else {
                mensagemSucesso('Cadastro realizado com sucesso! Realize login com seu email e senha')
            }
        })

    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setValorCpf(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleConfirmaSenha = (event) => {
        event.persist();
        setConfirmaSenha(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(()=>{
            window.location = '/'
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                    {/* Register */}
                    <div className="card">
                        <div className="card-body">
                            <img onClick={()=> window.location.href = 'https://tvcombate.com'} src='/images/tvcombate_logo_login.png' style={{ width: '100%' }} />
                            <h4 className="mb-2" style={{ marginTop: 20, textAlign: 'center' }}>Novo Cadastro</h4>
                            <p className="mb-4" style={{ marginTop: 20, textAlign: 'center' }}>Digite abaixo suas credenciais</p>
                            <div className="mb-3">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Nome</label>
                                    <input type="text" value={nome} onChange={handleNome} className="form-control" id="email" name="email-username" placeholder="Seu nome" autofocus />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">CPF</label>
                                    <InputMask onChange={handleCpf} mask="999.999.999-99" class="form-control" maskChar={null} value={valorCpf} placeholder='000.000.000-00' />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" value={email.toLocaleLowerCase()} onChange={handleEmail} className="form-control" id="email" name="email-username" placeholder="Digite seu email" autofocus />
                                </div>
                                <div className="mb-3 form-password-toggle">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label" htmlFor="password">Senha</label>
                                    </div>
                                    <div className="input-group input-group-merge">
                                        <input value={senha} onChange={handleSenha} type="text" id="password" className="form-control" name="password" placeholder="············" aria-describedby="password" />
                                        <span className="input-group-text cursor-pointer"><i className="bx bx-hide" /></span>
                                    </div>
                                </div>
                                <div className="mb-3 form-password-toggle">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label" htmlFor="password">Confirme sua senha</label>
                                    </div>
                                    <div className="input-group input-group-merge">
                                        <input value={confirmaSenha} onChange={handleConfirmaSenha} type="text" id="password" className="form-control" name="password" placeholder="············" aria-describedby="password" />
                                        <span className="input-group-text cursor-pointer"><i className="bx bx-hide" /></span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <button onClick={() => realizaLogin()} className="btn btn-primary d-grid w-100" type="submit">Cadastrar</button>
                                    <button onClick={() => window.location = '/'} className="btn btn-default d-grid w-100" type="submit">Já possui cadastro? Faça login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}