import React, { useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import { listaEventos, deletaEvento, listaUsuarios, listaSalaVip, criaSalaVip, deletaSalaVip } from '../../../services/mysql'

export default function Produtos() {
    const queryParams = new URLSearchParams(window.location.search);
    const [academias, setAcademias] = useState([])
    const [id] = useState(queryParams.get('i'))
    const [video] = useState(queryParams.get('video'))
    const [usuarios, setUsuarios] = useState([])
    const [salaVip, setSalaVip] = useState([])
    const [usuario, setUsuario] = useState({})

    useEffect(() => {
        mensagemLoading('Buscando Usuários na sala VIP...')
        if (id) {
            listaUsuarios().then((res) => {
                if (res.erro) {
                    mensagemErro('Erro ao buscar usuarios')
                } else {
                    Swal.close()
                    setUsuarios(res)
                }
            })
            listaSalaVip({ tipo: 'evento', id: id }).then((res) => {
                if (res.erro) {
                    mensagemErro('Erro ao buscar sala vip')
                } else {
                    Swal.close()
                    setSalaVip(res)
                }
            })
        }
    }, {})

    function cadastraSala() {
        if (!usuario.id) {
            mensagemAlerta('Selecione um usuário')
            return
        } else {
            Swal.fire({
                title: 'Aviso',
                text: 'Deseja adicionar o usuário ' + usuario.nome + ' a sala VIP desta transmissão  ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    mensagemLoading('Cadastrando usuário a sala VIP')
                    const data = {
                        idUsuario: usuario.id,
                        idSala: id,
                        tipo: 'evento'
                    }
                    criaSalaVip(data).then((res) => {
                        if (res.erro) {
                            mensagemErro('Erro ao deletar usuário da sala vip')
                        } else {
                            mensagemSucesso(res.message)
                        }
                    })
                }
            });

        }
    }

    function removeSala(item) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover o usuário ' + item.usuarioNome + ' da sala VIP desta transmissão  ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Removendo usuário da sala VIP')
                const data = {
                    idUsuario: item.usuarioId,
                    idSala: id,
                    tipo: 'evento'
                }
                deletaSalaVip(data).then((res) => {
                    if (res.erro) {
                        mensagemErro('Erro ao cadastrar usuário na sala vip')
                    } else {
                        mensagemSucesso('Usuário adicionado a sala vip com sucesso!')
                    }
                })
            }
        });
    }

    // Sala Vip
    const handleUsuario = (event) => {
        event.persist();
        setUsuario(usuarios[event.target.value]);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            mensagemLoading('Atualizando Sala VIP...')
            listaSalaVip({ tipo: 'evento', id: id }).then((res) => {
                if (res.erro) {
                    mensagemErro('Erro ao buscar sala vip')
                } else {
                    Swal.close()
                    setSalaVip(res)
                }
            })
        })
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 " style={{ marginTop: 20 }}>
                                <div className="card">
                                    <div className='row'>
                                        <div className='col-md-10'>
                                            <h5 className="card-header">Sala Vip do Vídeo - {video}</h5>
                                        </div>
                                    </div>

                                    {/* Account */}
                                    <div className="card-body">
                                        <div onsubmit="return false">
                                            <div className="row">
                                                <div class="mb-3 col-md-6">
                                                    <label class="form-label" for="basic-icon-default-fullname">Usuários</label>
                                                    <select id="defaultSelect" className="form-select" onChange={handleUsuario} >
                                                        <option>Selecione</option>
                                                        {usuarios.map((u, index) =>
                                                            <option key={u.id} value={index}>{u.nome} - {u.cpf}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div class="mb-3 col-md-3" style={{ marginTop: 30 }}>
                                                    <button onClick={() => cadastraSala()} type="submit" className="btn btn-primary me-2">Adicionar</button>
                                                </div>
                                            </div>
                                            <div className="table-responsive text-nowrap">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Usuário</th>
                                                            <th>CPF</th>
                                                            <th>Email</th>
                                                            <th>Remover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="table-border-bottom-0">
                                                        {salaVip.map((sala) =>
                                                            <tr key={sala.id}>
                                                                <td>{sala.usuarioNome}</td>
                                                                <td>{sala.usuarioCpf}</td>
                                                                <td>{sala.usuarioEmail}</td>
                                                                <td>
                                                                    <button onClick={() => removeSala(sala)} type="button" className="btn rounded-pill btn-icon btn-danger">
                                                                        <span className="tf-icons bx bx-trash" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {salaVip.length === 0 ?
                                                            <p style={{ textAlign: 'center', padding: 10 }} >Sem usuarios na sala vip</p>
                                                            :
                                                            null
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}