import React from 'react';
import './app.css';
import Swal from 'sweetalert2'

const ModalPix = ({ textToCopy,qrcode, onClose }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => mensagemLoading('Faça o Pix, volte e aguarde a transmissão.<br /><p>Texto Copiado com sucesso</p>'))
      .catch(err => alert('Erro ao copiar texto: ' + err));
  };

  function mensagemLoading(msg) {
    Swal.fire({
      title: 'Não feche a Tela',
      html: msg,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
    })
  }

  return (
    <div className="modal">
      <div className="modal-content">
        {/* Botão de fechamento */}
        <span className="close" onClick={onClose}>&times;</span>

        {/* Imagem (QR Code) */}
        <img
          style={{
            width: '80%',
            height: 'auto', // Melhor ajuste para evitar distorções
            display: 'block', // Garante centralização com margens automáticas
            margin: '0 auto'
          }}
          src={`data:image/png;base64,${qrcode}`}
          alt="QR Code"
        />

        {/* Texto copiado */}
        <p
          style={{
            textAlign: 'center',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            backgroundColor: '#f9f9f9', // Fundo leve para destacar
            padding: '1rem',
            borderRadius: '8px',
            fontFamily: 'monospace', // Para exibir bem texto como códigos
            overflowWrap: 'break-word',
            fontSize:12
          }}
        >{textToCopy}</p>

        {/* Botão para copiar texto */}
        <button
          className="copy-btn"
          onClick={copyToClipboard}
          style={{
            display: 'block',
            margin: '1rem auto', // Centraliza o botão
            padding: '0.5rem 1rem', // Ajusta o tamanho
            backgroundColor: '#007bff', // Cor primária
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Copiar Texto
        </button>
      </div>

    </div>
  );
}

export default ModalPix;
