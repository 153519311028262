import React from 'react';
export default function Footer() {
    return (
        <footer className="content-footer footer bg-footer-theme">
            <div className="container-xxl d-flex justify-content-center py-2">
                <div className="text-center">
                    Desenvolvido e aplicado por
                    <a style={{ marginLeft: 10 }} href="#" target="_blank" className="footer-link fw-bolder">TvCombate</a>
                </div>
            </div>
        </footer>

    );
}

