import { useEffect, useRef } from "react";
import Hls from "hls.js";

function VideoModal({ playerNumber, onClose }) {
  const videoRef = useRef(null);
  const url = getPlayerUrl(playerNumber);

  useEffect(() => {
    if (!url) {
      console.error("URL inválida ou número de player não configurado.");
      return;
    }
    // if (Hls.isSupported()) {
    //   const hls = new Hls();
    //   hls.loadSource(url); // Carrega o vídeo em formato m3u8
    //   hls.attachMedia(videoRef.current); // Associa o HLS.js ao elemento de vídeo
    // } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
    //   // Suporte nativo para navegadores como Safari
    //   videoRef.current.src = url;
    // } else {
    //   console.error("Seu navegador não suporta HLS.");
    // }
  }, [url]);

  if (!url) {
    return (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>&times;</span>
          <p style={{ color: "red", textAlign: "center" }}>
            Player não configurado ou número inválido.
          </p>
        </div>
      </div>
    );
  }

  function getPlayerUrl(number) {
    const playerLinks = {
      1: "https://player.logicahost.com.br/player.php?player=1808",
      2: "https://player.logicahost.com.br/player.php?player=1859",
      3: "https://player.logicahost.com.br/player.php?player=1898",
      4: "https://player.logicahost.com.br/player.php?player=1899",
    };

    return playerLinks[number] || null; // Retorna o link correspondente ou null se não existir
  }

  return (
    // <div className="modal">
    //   <div className="modal-content">
    //     <span className="close" onClick={onClose}>&times;</span>
    //     <video
    //       ref={videoRef}
    //       width="100%"
    //       height="100%"
    //       controls
    //       autoPlay
    //       playsInline
    //     ></video>
    //     <button onClick={onClose} className="btn btn-default d-grid w-100" type="button">
    //       Fechar
    //     </button>
    //     <p style={{color:'red',textAlign:'center',fontSize:10}}>Caso a transmissão fique com a tela toda escura, atualize a página</p>
    //   </div>
    // </div>
    <div className="modal">
      <div className="modal-content">
      <span className="close" onClick={onClose}>&times;</span>
        <p style={{  textAlign: 'center', fontSize: 20,color:'red' }}><b>Atenção</b></p>
        <p style={{  textAlign: 'center', fontSize: 15,color:'red' }}>
          Aumente o volume ou clique no <b><i className="menu-icon tf-icons bx bx-volume-full" /></b>de volume
        </p>
        
        <iframe
          width="100%"
          height="100%"
          src={url}
          title="Transmissão"
          frameBorder="0"
          allowFullScreen
          style={{ border: 'none' }}
        ></iframe>

        <button onClick={onClose} className="btn btn-default d-grid w-100" type="button">
          Fechar
        </button>
        <p style={{ color: 'red', textAlign: 'center', fontSize: 10 }}>
          Caso a transmissão fique com a tela toda escura, atualize a página
        </p>
      </div>
    </div>
  );
}

export default VideoModal;
