import React, { useRef, useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import Modal from './Modal';

import { buscaTransmissao, verificaTransmissao, criaTransmissaoUser, criaPix, buscaPagamento } from '../../../services/mysql'
import ModalPix from './ModalPix';

export default function Videos() {
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('i'))
    const [academia] = useState(queryParams.get('a'))
    const [transmissao, setTransmissão] = useState({})

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [isModalOpenPix, setIsModalOpenPix] = useState(false);
    const openModalPix = () => setIsModalOpenPix(true);
    const closeModalPix = () => setIsModalOpenPix(false);

    const [termo, setTermo] = useState(true)

    const [urlVideo, setUrlVideo] = useState('')

    // Pagamento
    const [qrcode, setQrcode] = useState('')
    const [copiaCola, setCopiaCola] = useState('')
    const intervalId = useRef(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userd7'))
        mensagemLoading('Buscando Dados...')
        buscaTransmissao(id).then((res) => {
            if (res.erro) {
                Swal.close()
                mensagemErro('Erro ao buscar vídeos')
            } else {
                Swal.close()
                setTransmissão(res.transmissao)
            }
        })
    }, {})

    const startApiCalls = (pagamento, transmissao) => {
        fetchApiData(pagamento, transmissao);
        intervalId.current = setInterval(() => {
            fetchApiData(pagamento, transmissao);
        }, 10000);
    };

    const fetchApiData = async (pagamento, transmissao) => {
        try {
            buscaPagamento(pagamento).then((res) => {
                if (res.status === 'approved') {
                    const user = JSON.parse(localStorage.getItem('userd7'))
                    const data = {
                        idTransmissao: id,
                        idUser: user.id
                    }
                    criaTransmissaoUser(data).then((resTransmissao) => {
                        if (resTransmissao.erro) {
                            mensagemErro('Erro ao gerar transmissaão')
                        } else {
                            closeModalPix()
                            setUrlVideo(transmissao.canalTransmissao)
                            openModal()
                            Swal.close()
                            // mensagemSucesso('Seu download já foi iniciado!')
                            // handleDownload(video.urlDownload)
                        }
                    })
                    clearInterval(intervalId.current);
                }
            });
        } catch (err) {
            mensagemErro('Erro ao realizar pagamento');
        }
    };

    function realizaTransmissao() {
        const user = JSON.parse(localStorage.getItem('userd7'))
        mensagemLoading('Verificando..')
        verificaTransmissao(user.id, id).then((res) => {
            if (res.erro) {
                mensagemErro('Erro ao verificar transmissao')
            } else {
                console.log(res)
                if (res.transmissao || res.salaVip) {
                    Swal.close()
                    setUrlVideo(transmissao.canalTransmissao)
                    openModal()
                } else {
                    Swal.fire({
                        title: 'Aviso',
                        text: 'Você precisa realizar o pagamento de ' + transmissao.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) + ' para assistir a transmissão ' + transmissao.nome + '! Deseja realizar o pagamento?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Pagar',
                        cancelButtonText: 'Agora Não'
                    }).then((result) => {
                        if (result.value) {
                            const dados = {
                                nome: 'TVCombate - Transmissão',
                                cpf: user.cpf,
                                email: user.email,
                                descricao: 'Transmissao - ' + transmissao.nome,
                                valor: parseFloat(transmissao.valor),
                            }
                            mensagemLoading('Gerando pix..')
                            criaPix(dados).then((res) => {
                                setQrcode(res.data.pagamento.qrcode)
                                setCopiaCola(res.data.pagamento.chave)
                                Swal.close()
                                startApiCalls(res.data.id, transmissao)
                                openModalPix()
                            })
                        }
                    });
                }
            }
        })
    }

    const extractFileIdGoogle = (url) => {
        const regex = /\/d\/(.*?)\//;  // Expressão regular para pegar o ID entre "/d/" e "/"
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const handleDownload = (urlVideo) => {
        if (!urlVideo) {
            alert('A URL do vídeo é necessária');
            return;
        }

        // Cria dinamicamente um link para fazer a requisição ao backend com a URL do vídeo
        const link = document.createElement('a');
        link.href = `https://app-3prv6jubba-uc.a.run.app/downloadVideo?url=${encodeURIComponent(urlVideo)}`;  // Envia a URL como query parameter

        // Força a abertura da janela de download
        link.target = '_blank';  // Abre o link em uma nova aba (alguns navegadores tratam isso como uma nova ação de download)
        link.download = '';      // O atributo download vazio forçará o diálogo de download para o usuário escolher onde salvar

        // Simula o clique no link para iniciar o download
        document.body.appendChild(link);
        link.click();

        // Remove o link do DOM após o clique
        document.body.removeChild(link);
    };

    function assitirVideo(item) {
        setUrlVideo(item)
        openModal()
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                {/* <Menu /> */}
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h3><b>{academia}</b></h3>
                                <button onClick={() => window.location = '/homeAtleta'} style={{ marginTop: 20, width: '30%' }} className="btn btn-primary d-grid " type="submit">{'< Voltar'}</button>
                                <div className="col-sm-12 col-lg-12 mb-4" style={{ marginTop: 20 }}>
                                    <div className="card card-border-shadow-primary h-100">
                                        {transmissao && transmissao.id ?
                                            <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                <img style={{ width: 200, height: 200 }} src={transmissao.urlImagem} />
                                                <h2 style={{ marginTop: 20 }}>{transmissao.nome}</h2>
                                                <h5>TERMOS DE ACEITE</h5>
                                                <p>Versão : v3</p>
                                                <div style={{ marginTop: 20, alignItems: 'start', textAlign: 'start' }}>
                                                    <h5><b>Apresentação:</b></h5>
                                                    <p>Muito obrigado por você está aqui na Plataforma <b>tvcombate.com</b>, pra poder Assistir a Transmissão ao vivo do Evento que você escolheu.</p>
                                                    <p>Antes de clicar em qualquer botão, leia com atenção as informações, e veja os vídeos feito pra você ! Para que você tenha maior clareza antes de qualquer ação.</p>

                                                    <h5><b>A Internet:</b></h5>
                                                    <p>A internet fornecida para a Transmissão do Evento, não é de responsabilidade da Plataforma Tvcombate.com e nem do Proprietário(a) do Evento.</p>
                                                    <p>Caso venha ocorrer algum problema durante a Transmissão do Evento, como: Falta da internet, falta de energia, problemas no som, na iluminação ou qualquer problema. NÃO nos responsabilizamos e nem ressarcimos o telespectador(a).</p>
                                                    <p>A Transmissão ao vivo do Evento, continuará normalmente sem pausas. Pois a Transmissão está sendo GRAVADO e TRANSMITINDO ao mesmo tempo. Caso o problema seja resolvido, a Transmissão ao vivo continuará de onde parou.</p>

                                                    <h5 style={{ marginTop: 20 }}><b>Responsável sobre PIX:</b></h5>
                                                    <p>O pix é recebido pela melhor plataforma de recebimentos, o Mercado pago.</p>
                                                    <p>O valor informado pra Assistir a Transmissão do Evento com data, dia e horário é estipulada pelo Proprietário(a) do Evento.</p>

                                                    <h5 style={{ marginTop: 20 }}><b>Dúvidas?</b></h5>
                                                    <p>Tire suas dúvidas no botão whatsApp no popup que abre ao entrar na Plataforma tvcombate.com. Esse whatsApp é diretamente com o proprietário(a) do Evento ou Responsável.</p>

                                                    <h5 style={{ marginTop: 20 }}><b>Login e Senha:</b></h5>
                                                    <p>A Plataforma <b>tvcombate.com</b> somente aceita um único Cadastro de login e senha. E é válido somente para o dia, horário e data da Transmissão do Evento. Lembre-se de se Cadastrar corretamente, com dados válidos.</p>

                                                    <h5 style={{ marginTop: 20 }}><b>Vídeos Pós Evento:</b></h5>
                                                    <p>Para mais informações sobre os vídeos da Transmissão do Evento, basta acessar periodicamente a Plataforma <b>tvcombate.com</b>. e clicar no ícone play na cor Branca, ou botão saiba mais junto ao logo do Evento.</p>

                                                    <h5 style={{ marginTop: 20 }}><b>Os vídeos terão algum valor ?</b></h5>
                                                    <p>Somente após a Negociação junto ao Proprietário(a) do Evento, será possível informarmos na Plataforma <b>tvcombate.com</b>, se os vídeos terão ou não terão algum custo pra Baixa-los.</p>
                                                    <p>Basta você entrar periodicamente na Plataforma <b>tvcombate.com</b>, clicar no play "cor branca" e localizar o logo do Evento. Afinal, sempre terá informações referente a transmissão do Evento.</p>


                                                    <h5 style={{ marginTop: 20 }}><b>PIX:</b></h5>
                                                    <p>Ao concordar em fazer o pix, o Sistema do Mercado Pago irá gerar um Código em Texto e o Qrcode.</p>
                                                    <p style={{ color: 'red' }}><b>Atenção: Não feche a tela com código pix.</b></p>
                                                    <p style={{ color: 'red' }}><b>Atenção, Checar primeiro sua internet</b></p>
                                                    <ul>
                                                        <li><u>01 Copie código pix</u></li>
                                                        <li><u>02 Abra app banco</u></li>
                                                        <li><u>03 Cole o código pix</u></li>
                                                        <li><u>04 Confirma o pagamento</u></li>
                                                        <li><u>05 Volte para a tela do código pix</u></li>
                                                        <li><u>06 Aguarde, não saia dessa tela</u></li>
                                                        <li><u>07 Ao completar irá aparecer a Tela da Transmissão</u></li>
                                                        <li><u>08 Se não aparecer nada, ficar em branco ou alguma informação sem ser a transmissão, foi aceito. Apenas a Transmissão não começou, só aguardar.</u></li>
                                                    </ul>
                                                    <p>Tenha CERTEZA em Assistir a Transmissão do Evento com dia, data e horário. Pois a Transação do pix é aceito apenas uma vez, e servirá somente pra você.</p>

                                                    <p><b>Att, Plataforma tvcombate.com V.3</b></p>

                                                </div>
                                                <p>Valor para assistir esta transmissão</p>
                                                <h5 ><b>{transmissao.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></h5>

                                                <button onClick={() => realizaTransmissao()} style={{ marginTop: 20 }} className="btn btn-success d-grid w-100" type="submit">
                                                    <i className="menu-icon tf-icons bx bx-video" />Concordar e Assistir Transmissão
                                                </button>
                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                                {isModalOpen && urlVideo && <Modal onClose={closeModal} playerNumber={urlVideo} />}
                                {isModalOpenPix && <ModalPix textToCopy={copiaCola} qrcode={qrcode} onClose={closeModalPix} />}
                            </div>
                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}